import React from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../../hooks/useUiStore";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CallButtons from "../../../components/CallButtons";
import RedTriangle from "../../../assets/webp/red-triangle.webp";
import RedTriangle2 from "../../../assets/webp/red-triangle-2.webp";
import Image22 from "../../../assets/carousel/22.webp";
import Image38 from "../../../assets/carousel/38.webp";
import ArticleFooter from "../../../components/ArticleFooter";

const Savannah = () => {
  const { lang } = useUiStore();

  function createData(service1, service2, service3) {
    return { service1, service2, service3 };
  }

  const rows = [
    createData(
      "24 Hour Roadside Assistance in Savannah GA",
      "Semi Trailer Repair",
      "Truck Repair and Manteinance"
    ),
    createData("Mobile Tire Repair", "Mobile Diesel Mechanic", "Tire Change"),
    createData("Travel Mechanic", "Trailer Repair", "Truck Box Repair"),
    createData("Truck AC Repair", "Fleet Truck Repair", "Computer Diagnostics"),
    createData("Computer Diagnostics", "Hydraulic Repair", "RV Services"),
    createData("Jumpstart Services", "Batteries Replacement", "Wheels Repair"),
    createData("Bearing Service", "Oil Change", "Lockout Service"),
    createData("DPF Cleaning", "Brakes Services", "Mobile Truck Repair"),
  ];

  const spanish_rows = [
    createData(
      "24 Hour Roadside Assistance in Savannah GA",
      "Reparación de Semi Trailer",
      "Reparación y Mantenimiento de Camiones"
    ),
    createData(
      "Reparación Móvil de Neumáticos",
      "Mecánicos Diésel Móviles",
      "Cambio de Neumáticos"
    ),
    createData(
      "Travel Mechanic",
      "Reparación de Tráiler",
      "Reparación de Truck Box"
    ),
    createData(
      "Reparación de Aire Acondicionado de Camiones",
      "Reparación de Flotas de Camiones",
      "Diagnóstico Computarizado"
    ),
    createData(
      "Computer Diagnostics",
      "Reparación Hidráulica",
      "Servicio de RV"
    ),
    createData(
      "Servicio de Arranque",
      "Cambio de de Baterías",
      "Reparación de Ruedas"
    ),
    createData(
      "Servicio de Rodamientos",
      "Cambio de Aceite",
      "Servicio de Apertura de Puertas"
    ),
    createData(
      "DPF Cleaning",
      "Servicio de Frenos",
      "Reparación Móvil de Camiones"
    ),
  ];
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/savannah" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "Traffic never stops on the roads of Savannah. Our 24/7 roadside service will get you out of any trouble with your semi truck fast. Call us at +1 407 674 5769 🚛."
              : "El tráfico nunca para en las carreteras de Savannah. Nuestro servicio 24/7 te saca rápido de cualquier apuro con tu camión. Llámanos al +1 407 674 5769 🚛."
          }`}
        />
        <title>
          {lang === "EN"
            ? "The Best Roadside Assistance in Savannah, Georgia"
            : "La Mejor Asistencia en Carretera en Savannah, Georgia"}
        </title>
      </Helmet>
      <Navbar />

      {/* Desktop Image View */}
      <Box
        alt="Mechanical Service about us banner"
        title="Mechanical Service about us banner"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle});`,
          backgroundPosition: "center",
          color: "white",
          height: 300,
          border: 0,
          textAlign: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ paddingTop: 15, fontSize: 40, fontFamily: "Ethnocentric" }}
        >
          Savannah
        </Typography>
        <CallButtons />
      </Box>

      {/* Phone and tablet Image View */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle2});`,
          backgroundPosition: "center right",
          color: "white",
          border: 0,
          textAlign: "center",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
          pb: 10,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 40, paddingTop: 12, fontFamily: "Ethnocentric" }}
        >
          Savannah
        </Typography>
        <CallButtons />
      </Box>

      <Box>
        <Container sx={{ mt: 10, mb: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: 40,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
              >
                {lang === "EN"
                  ? "Roadside Assistance in Savannah"
                  : "Asistencia en Carretera en Savannah"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {lang === "EN" ? (
                <>
                  <p>
                    Roadside assistance in Savannah is a fundamental need for
                    every semi truck driver, and at Cioffi Express Services we
                    know that. That's why we provide our roadside services
                    throughout Savannah and 100 miles around.
                  </p>

                  <p>
                    If you're cruising the city roads and your semi truck shows
                    any signs of breaking down, don't hesitate to stop your ride
                    and{" "}
                    <b>
                      request the best{" "}
                      <a target="_blank" href="https://cestruckrepair.com/">
                        roadside assistance service
                      </a>{" "}
                      in Savannah
                    </b>
                    .
                  </p>

                  <p>
                    Call us at <b>+1 407 674 5769</b> and we will take your call
                    immediately.
                  </p>

                  <h2 style={{ paddingTop: 15 }}>
                    Mobile Semi Truck & Trailer Repair
                  </h2>

                  <p style={{ paddingTop: 15 }}>
                    The road to Port Savannah, one of the busiest on the east
                    coast, is a convergence point for freight vehicles.
                    Interstates 95, 65 and 75 are among the most heavily used
                    trade routes in the region.{" "}
                    <b>
                      Goods deliveries of much of this part of the country
                      depend on the flow of vehicles in Savannah
                    </b>
                    . That's why CES roadside assistance in Savannah, GA is
                    critical.
                  </p>

                  <p>
                    Savannah is a large city with numerous avenues and streets
                    among which you can lose many hours searching for a reliable
                    mechanic shop that repairs heavy vehicles. The best option
                    if you're facing a roadside emergency is to{" "}
                    <b>
                      stay calm and save time and money by calling{" "}
                      <a
                        target="_blank"
                        href="https://cestruckrepair.com/contact"
                      >
                        our 24/7 help center
                      </a>
                    </b>
                    .
                  </p>

                  <h2 style={{ paddingTop: 15 }}>
                    The Best Roadside Assistance in Savannah, Georgia
                  </h2>

                  <p style={{ paddingTop: 15 }}>
                    Thanks to Cioffi Express Services,{" "}
                    <b>Georgia roadways are monitored</b> so that when a heavy
                    vehicle driver needs help, they can get our roadside
                    assistance in Savannah and 100 miles around just by{" "}
                    <b>sending a message or calling +1 407 674 5769</b>.
                  </p>

                  <p>
                    To learn more about our services or gain new knowledge
                    regarding heavy vehicles care and maintenance visit our
                    blog.{" "}
                    <b>
                      Get back on the road safe with Cioffi Express Services
                    </b>
                    !
                  </p>

                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image22}
                      alt="CES Trucks Banner"
                      title="CES Trucks Banner"
                    />
                  </Grid>

                  <h2 style={{ paddingTop: 50 }}>
                    What Services Does Cioffi Express Offer in Savannah?
                  </h2>

                  <p style={{ paddingTop: 15 }}>
                    Downtime due to semi truck breakdowns can be very costly.
                    But with our roadside assistance in Savannah you will get
                    back on the road quickly without spending too much. Here are
                    our main{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/services"
                    >
                      services
                    </a>
                    :
                  </p>

                  <h3 style={{ paddingTop: 15, color: "#626567" }}>
                    Mechanical Roadside Assistance
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    <b>Semi truck repair requires specific equipment</b>, such
                    as heavy-duty hydraulic jacks, electronic diagnostic systems
                    and large tools.
                  </p>

                  <p>
                    Our roadside assistance in Savannah includes{" "}
                    <b>general on-site repairs</b>, such as air brake
                    troubleshooting, fuel system and diesel engine fixes, and
                    preventive overhauls.
                  </p>

                  <p>
                    <b>
                      Cioffi Express Services provides the most complete
                      roadside assistance throughout Savannah and 100 miles
                      around
                    </b>
                    . Our service is vital to keeping fleets operational,
                    ensuring safety on the road and avoiding accidents that
                    could result from serious mechanical failures.
                  </p>

                  <h3 style={{ paddingTop: 15, color: "#626567" }}>
                    Electrical Service
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    Your truck's electrical system is the equivalent of the
                    human circulatory system. The power running through the
                    internal wiring keeps everything from engine starting to the
                    control of complex systems such as brake assist and load
                    monitoring equipment running.
                  </p>

                  <p>
                    Electrical failures are common in vehicles that travel long
                    distances, especially due to variable weather conditions in
                    the area. An <b>alternator malfunction or a dead battery</b>{" "}
                    can leave a semi truck stranded, requiring immediate
                    roadside assistance in Savannah Georgia.
                  </p>

                  <p>
                    Our electrical roadside assistance services include
                    <b>
                      identifying charging system problems, replacing batteries
                      and diagnosing electronic malfunctions
                    </b>
                    . This ensures that heavy vehicles can continue on their
                    route without compromising safety or operational efficiency.
                  </p>

                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image38}
                      alt="CES Trucks Banner"
                      title="CES Trucks Banner"
                    />
                  </Grid>

                  <h3 style={{ paddingTop: 40, color: "#626567" }}>
                    Tire Change
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    On-road tire changes are{" "}
                    <b>
                      one of the most frequent services for heavy vehicles in
                      Savannah
                    </b>
                    , due to the large amount of truck traffic to and from the
                    Port. The long distances traveled and the weight of the
                    loads generate accelerated tire wear, which increases the
                    risk of punctures and damage that can leave your truck
                    stranded.
                  </p>

                  <p>
                    Our tire changing service is performed with specialized
                    equipment that allows us to{" "}
                    <b>lift and handle large truck tires quickly and safely</b>.
                    This is essential to reduce your vehicle's downtime and
                    ensure that it continues to operate with tires in optimal
                    and safe condition.
                  </p>

                  <p>
                    In addition, you can choose between <b>new or used tires</b>
                    , or even <b>tire repair</b> when possible.
                  </p>

                  <h3 style={{ paddingTop: 15, color: "#626567" }}>
                    Other Services
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    Cioffi Express Services is a family owned and operated
                    business with over 20 years of experience providing roadside
                    assistance in Georgia and Florida. See{" "}
                    <b>the list of services available to you</b>.
                  </p>

                  <TableContainer sx={{ marginTop: 5 }} component={Paper}>
                    <Table aria-label="simple table">
                      {/* <TableHead>
                        <TableRow>
                          <TableCell>Dessert (100g serving)</TableCell>
                          <TableCell align="right">Calories</TableCell>
                          <TableCell align="right">Fat&nbsp;(g)</TableCell>
                          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                          <TableCell align="right">Protein&nbsp;(g)</TableCell>
                        </TableRow>
                      </TableHead> */}
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.service1}>
                            <TableCell component="th" scope="row">
                              {row.service1}
                            </TableCell>
                            <TableCell>{row.service2}</TableCell>
                            <TableCell>{row.service3}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <p style={{ paddingTop: 40 }}>
                    The price of our roadside assistance services will depend on
                    the needs of your vehicle. To <b>request a free quote</b>{" "}
                    just{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/about-us"
                    >
                      visit our website
                    </a>
                    , at Cioffi Express Services{" "}
                    <b>we're committed to improve your previous quote</b>.
                  </p>

                  <h2 style={{ paddingTop: 15 }}>
                    FAQs: Frequently Asked Questions
                  </h2>

                  <Accordion sx={{ mt: 5 }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "rgb(253,211,131,0.7)" }}
                    >
                      <h3
                        style={{
                          paddingTop: 15,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        What is the Difference Between Roadside Assistance and
                        Roadside Recovery?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p style={{ paddingTop: 15 }}>
                        If you have an incident on the road it is important to{" "}
                        <b>know what type of service you require</b>. Depending
                        on the extent of the damage you will know if you need
                        roadside assistance near you in Savannah GA or if you're
                        looking for roadside recovery.
                      </p>

                      <p>
                        Basically{" "}
                        <b>
                          the difference between the two is the location of the
                          vehicle with respect to the road
                        </b>
                        . When after the mishap your semi truck can stop on the
                        road or in the surrounding areas, then{" "}
                        <b>roadside assistance</b> is the ideal service, because
                        the mechanics come with the necessary equipment to
                        <b>
                          diagnose and repair the breakdown where you are
                        </b>{" "}
                        so that you can continue your trip as soon as possible.
                      </p>

                      <p>
                        On the other hand,{" "}
                        <b>
                          roadside recovery is required when the vehicle suffers
                          an accident that takes it off the road
                        </b>
                        , leaving it in an unsuitable condition to be repaired
                        on site.
                      </p>

                      <p>
                        In general, this service is required in the event of
                        platform detachment, chassis breakage or severe vehicle
                        collisions. It consists of{" "}
                        <b>
                          a tow truck taking what is left of the semi truck and
                          transporting
                        </b>{" "}
                        it either by hook or on a flatbed to a workshop for a
                        thorough evaluation of the damage.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "rgb(253,211,131,0.7)" }}
                    >
                      <h3
                        style={{
                          paddingTop: 15,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        How Do I Get Roadside Assistance Near Me in Savannah,
                        Georgia?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p style={{ paddingTop: 15 }}>
                        The first thing you need to know is to understand your
                        semi truck or trailer. Heavy vehicles often give obvious
                        signs that they need urgent mechanical attention. If you
                        don't already know how to recognize them, you can check
                        out these
                        <a
                          target="_blank"
                          href="https://cestruckrepair.com/blog/failing-engine-10-symptoms-that-you-should-not-ignore"
                        >
                          10 Engine Symptoms You Shouldn't Ignore
                        </a>
                        .
                      </p>

                      <p>
                        If you think there's any damage in your semi truck or RV
                        during your trip,{" "}
                        <b>
                          request Cioffi Express roadside assistance near you
                        </b>
                        .
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "rgb(253,211,131,0.7)" }}
                    >
                      <h3
                        style={{
                          paddingTop: 15,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Is there Free Roadside Assistance in Georgia?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p style={{ paddingTop: 15 }}>
                        Yes, there are free roadside assistance services in
                        Georgia.
                        <b>The CHAMP program</b> (Coordinated Highway Assistance
                        and Maintenance Program) belongs to the Georgia
                        Department of Transportation (DOT) and provides roadside
                        assistance in emergency and non-emergency situations.
                      </p>

                      <p>
                        It covers three main functions: highway maintenance,
                        driver assistance and emergency response. While the
                        CHAMP program is an altruistic DOT initiative,{" "}
                        <b>they are not heavy vehicle specialists</b>.
                      </p>

                      <p>
                        The support they can provide to semi-truck and trailer
                        drivers is very limited. However, if your breakdown is
                        related to fuel shortages, obstructing road damage, or
                        vehicle accidents and collisions, you can request
                        support from this free program.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <p style={{ marginTop: 30 }}>
                    For all other repairs requiring roadside assistance in
                    Savannah GA there is Cioffi Express Services.{" "}
                    <b>
                      We are available 24/7, don't hesitate to{" "}
                      <a target="_blank" href="tel:14076745769">
                        call us
                      </a>
                      !
                    </b>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    La asistencia en carretera en Savannah es una necesidad
                    fundamental para todo conductor de camiones, y en Cioffi
                    Express Services lo sabemos. Por eso prestamos nuestros
                    servicios en carretera en toda Savannah y 100 millas a la
                    redonda.
                  </p>

                  <p>
                    Si estás recorriendo las carreteras de la ciudad y tu camión
                    presenta algún signo de avería, no dudes en detener tu
                    marcha y{" "}
                    <b>
                      solicitar el mejor{" "}
                      <a target="_blank" href="https://cestruckrepair.com/">
                        servicio de asistencia en carretera
                      </a>{" "}
                      en Savannah
                    </b>
                    .
                  </p>

                  <p>
                    Llámanos al <b>+1 407 674 5769</b> y responderemos de
                    inmediato.
                  </p>

                  <h2 style={{ paddingTop: 15 }}>
                    Reparación Móvil de Semi Truck y Trailers
                  </h2>

                  <p style={{ paddingTop: 15 }}>
                    El camino hacia Puerto Savannah, uno de los más activos de
                    la costa este, es un punto de convergencia para vehículos de
                    carga. Las interestatales 95, 65 y 75 son de las rutas
                    comerciales más utilizadas de la región.{" "}
                    <b>
                      La operatividad de buena parte del país depende de la
                      fluidez vehicular de Savannah
                    </b>
                    . Por eso la asistencia en carretera en Savannah, GA de CES
                    es fundamental.
                  </p>

                  <p>
                    Savannah es una ciudad grande con avenidas y calles
                    numerosas entre las que puedes perder muchas horas buscando
                    un taller mecánico de confianza que repare vehículos
                    pesados. La mejor opción ante una emergencia en carretera es
                    guardar la calma y ahorrar tiempo y dinero llamando a{" "}
                    <b>
                      {" "}
                      <a
                        target="_blank"
                        href="https://cestruckrepair.com/contact"
                      >
                        nuestro centro de ayuda{" "}
                      </a>
                      disponible las 24 horas, los 7 días de la semana
                    </b>
                    .
                  </p>

                  <h2 style={{ paddingTop: 15 }}>
                    La Mejor Asistencia en Carretera en Savannah, Georgia
                  </h2>

                  <p style={{ paddingTop: 15 }}>
                    Gracias a Cioffi Express Services,{" "}
                    <b> las avenidas de Georgia están monitoreadas</b> ara que
                    cuando algún conductor de vehículos pesados solicite auxilio
                    pueda contar con asistencia en carretera en Savannah y 100
                    millas a la redonda solo con
                    <b>enviar un mensaje o llamar al +1 407 674 5769</b>.
                  </p>

                  <p>
                    Para conocer más sobre nuestros servicios u obtener nuevos
                    conocimientos respecto al cuidado y mantenimiento de
                    vehículos pesados solo visita
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/contact"
                    >
                      {" "}
                      nuestro blog
                    </a>
                    .{" "}
                    <b>
                      ¡Vuelve a la carretera seguro con Cioffi Express Services!
                    </b>
                  </p>

                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image22}
                      alt="CES Trucks Banner"
                      title="CES Trucks Banner"
                    />
                  </Grid>

                  <h2 style={{ paddingTop: 50 }}>
                    ¿Qué Servicios Ofrece Cioffi Express en Savannah?
                  </h2>

                  <p style={{ paddingTop: 15 }}>
                    El tiempo de inactividad por fallas de un semi truck puede
                    ser muy costoso. Pero con la asistencia en carretera en
                    Savannah de Cioffi Express podrás volver rápidamente a la
                    carretera sin gastar demasiado. Estos son nuestros
                    principales{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/services"
                    >
                      servicios
                    </a>
                    :
                  </p>

                  <h3 style={{ paddingTop: 15, color: "#626567" }}>
                    Asistencia Mecánica en Carretera
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    <b>
                      La reparación de camiones requiere de equipos específicos
                    </b>
                    , como gatos hidráulicos de alta capacidad, sistemas de
                    diagnóstico electrónico y herramientas de gran tamaño
                  </p>

                  <p>
                    Nuestros servicios de asistencia en carretera incluyen{" "}
                    <b>reparaciones generales in situ</b>, como solucionar
                    problemas de frenos de aire, arreglos en sistemas de
                    combustible o motores diésel y revisiones preventivas.
                  </p>

                  <p>
                    <b>
                      Cioffi Express Services brinda la asistencia en carretera
                      en Savannah más completa de la región
                    </b>
                    . Nuestro servicio es vital para mantener la operatividad de
                    las flotas, garantizar la seguridad en las carreteras y
                    evitar accidentes que podrían derivarse de fallas mecánicas
                    graves.
                  </p>

                  <h3 style={{ paddingTop: 15, color: "#626567" }}>
                    Servicio Eléctrico
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    El sistema eléctrico de tu camión es el equivalente al
                    sistema circulatorio humano. La energía que recorre el
                    cableado interno mantiene funcionando desde el arranque del
                    motor hasta el control de sistemas complejos como el frenado
                    asistido y los equipos de monitoreo de carga.
                  </p>

                  <p>
                    Las fallas eléctricas son comunes en los vehículos que
                    recorren largas distancias, especialmente por las
                    condiciones climáticas variables de la zona. Un{" "}
                    <b>alternator malfunction or a dead battery</b> pueden dejar
                    a un camión varado, requiriendo asistencia en carretera
                    inmediata en Savannah Georgia.
                  </p>

                  <p>
                    Nuestros servicios de asistencia eléctrica en carretera
                    incluyen la
                    <b>
                      identificación de problemas en los sistemas de carga, la
                      sustitución de baterías y el diagnóstico de fallas
                      electrónicas
                    </b>
                    . Esto asegura que los vehículos puedan continuar su ruta
                    sin comprometer la seguridad ni la eficiencia operativa.
                  </p>

                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image38}
                      alt="CES Trucks Banner"
                      title="CES Trucks Banner"
                    />
                  </Grid>

                  <h3 style={{ paddingTop: 40, color: "#626567" }}>
                    Cambio de Neumáticos
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    El cambio de neumáticos en carretera es{" "}
                    <b>
                      uno de los servicios más frecuentes para vehículos pesados
                    </b>
                    en Savannah, debido a la gran cantidad de tráfico de
                    camiones que circulan desde y hacia el Puerto. Las largas
                    distancias recorridas y el peso de las cargas generan un
                    desgaste acelerado en los neumáticos, lo que aumenta el
                    riesgo de pinchazos y daños que pueden dejar varado a tu
                    camión.
                  </p>

                  <p>
                    Nuestro servicio de cambio de neumáticos lo realizamos con{" "}
                    <b>
                      equipos especializados que permiten levantar y manipular
                      los grandes neumáticos de camiones
                    </b>
                    . de manera rápida y segura. Esto es esencial para reducir
                    el tiempo de inactividad de tu vehículo y garantizar que
                    siga operando con neumáticos en condiciones óptimas y
                    seguras.
                  </p>

                  <p>
                    Además, puedes elegir entre{" "}
                    <b>
                      neumáticos nuevos o usados, o incluso la reparación del
                      neumático
                    </b>{" "}
                    cuando es posible.
                  </p>

                  <h3 style={{ paddingTop: 15, color: "#626567" }}>
                    Otros Servicios
                  </h3>

                  <p style={{ paddingTop: 15 }}>
                    Cioffi Express Services es una compañía familiar con más de
                    20 años de experiencia brindando asistencia en carretera en
                    Georgia y Florida. Conoce ahora la{" "}
                    <b>lista de servicios disponibles para ti</b>.
                  </p>

                  <TableContainer sx={{ marginTop: 5 }} component={Paper}>
                    <Table aria-label="simple table">
                      {/* <TableHead>
                        <TableRow>
                          <TableCell>Dessert (100g serving)</TableCell>
                          <TableCell align="right">Calories</TableCell>
                          <TableCell align="right">Fat&nbsp;(g)</TableCell>
                          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                          <TableCell align="right">Protein&nbsp;(g)</TableCell>
                        </TableRow>
                      </TableHead> */}
                      <TableBody>
                        {spanish_rows.map((row) => (
                          <TableRow key={row.service1}>
                            <TableCell component="th" scope="row">
                              {row.service1}
                            </TableCell>
                            <TableCell>{row.service2}</TableCell>
                            <TableCell>{row.service3}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <p style={{ paddingTop: 40 }}>
                    El precio de nuestros servicios de asistencia en carretera
                    dependerá de las necesidades que tenga tu vehículo. Para{" "}
                    <b>solicitar un presupuesto gratuito</b> solo{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/about-us"
                    >
                      ingresa a nuestro sitio web
                    </a>
                    , en Cioffi Express Services nos comprometemos a{" "}
                    <b>mejorar tu presupuesto anterior</b>.
                  </p>

                  <h2 style={{ paddingTop: 15 }}>FAQs: Preguntas Frecuentes</h2>

                  <Accordion sx={{ mt: 5 }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "rgb(253,211,131,0.7)" }}
                    >
                      <h3
                        style={{
                          paddingTop: 15,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        ¿Cuál es la Diferencia entre Asistencia en Carretera y
                        Recuperación en Carretera?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p style={{ paddingTop: 15 }}>
                        Si llegas a tener un incidente en la vía es importante
                        saber <b>reconocer qué tipo de servicio requieres</b>.
                        Depending on the extent of the damage you will know if
                        you need roadside assistance near you in Savannah GA or
                        if you're looking for roadside recovery.
                      </p>

                      <p>
                        Básicamente{" "}
                        <b>
                          la diferencia entre una y otra es la ubicación del
                          vehículo con respecto a la carretera
                        </b>
                        . Cuando después del percance tu semi truck puede
                        detenerse en carretera o en las áreas que colindan,
                        entonces la <b>asistencia en carretera</b> (roadside
                        assistance) es el servicio ideal, debido a que los
                        mecánicos se movilizan con el equipo necesario para
                        <b>diagnosticar y reparar la avería en el lugar</b>{" "}
                        donde te encuentres de manera que continues tu recorrido
                        cuanto antes.
                      </p>

                      <p>
                        Por su parte, la{" "}
                        <b>
                          roadside recovery es requerida cuando el vehículo
                          sufre un accidente que lo saca de la carretera
                        </b>
                        dejándolo en condiciones no aptas para ser reparadas en
                        el sitio.
                      </p>

                      <p>
                        Por lo general, este servicio se requiere en caso de
                        desprendimiento de la plataforma, ruptura de chasis o
                        colisiones vehiculares fuertes. Consiste en que{" "}
                        <b>
                          un camión remolque tome lo que queda del semi truck
                        </b>{" "}
                        y lo lleve bien sea con gancho o en una plataforma hasta
                        un taller automotriz para una evaluación profunda del
                        daño.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "rgb(253,211,131,0.7)" }}
                    >
                      <h3
                        style={{
                          paddingTop: 15,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        ¿Cómo Consigo Asistencia en Carretera Cerca de Mí en
                        Savannah, Georgia?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p style={{ paddingTop: 15 }}>
                        Lo primero que tienes que saber es entender a tu semi
                        truck o trailer. Los vehículos pesados suelen dar
                        señales evidentes de que necesitan atención mecánica
                        urgente. Si aún no sabes reconocerlas, puedes revisar
                        estos{" "}
                        <a
                          target="_blank"
                          href="https://cestruckrepair.com/blog/failing-engine-10-symptoms-that-you-should-not-ignore"
                        >
                          10 Síntomas De Daño En El Motor Que No Deberías
                          Ignorar
                        </a>
                        .
                      </p>
                      <p>
                        Si te encuentras en aprietos durante tu viaje,{" "}
                        <b>
                          {" "}
                          solicita la asistencia en carretera cerca de ti de
                          Cioffi Express Services{" "}
                        </b>
                        .
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "rgb(253,211,131,0.7)" }}
                    >
                      <h3
                        style={{
                          paddingTop: 15,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        ¿Hay Asistencia en Carretera Gratis en Georgia?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p style={{ paddingTop: 15 }}>
                        Si, existen servicios gratuitos de asistencia en
                        carretera en Georgia.
                        <b>El programa CHAMP</b> (Coordinated Highway Assistance
                        and Maintenance Program) pertenece al Georgia Department
                        of Transportation (DOT) y se ocupa de brindar apoyo en
                        carretera en situaciones de emergencia y no emergencia.
                      </p>

                      <p>
                        Su rango de acción cubre tres funciones principales:
                        mantenimiento de carreteras, asistencia a los
                        conductores y respuesta a emergencias. Si bien el
                        programa CHAMP es una iniciativa altruista del DOT,{" "}
                        <b>no son especialistas en vehículos pesados</b>.
                      </p>

                      <p>
                        El apoyo que pueden brindar a los conductores de semi
                        truck y trailer es muy limitado. Sin embargo si tu
                        percance o avería está relacionado con falta de
                        combustible, daños obstaculizantes en la carretera o
                        accidentes y colisiones vehiculares puedes solicitar el
                        apoyo de este programa gratuito.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <p style={{ marginTop: 30 }}>
                    Para todas las demás reparaciones que requieran asistencia
                    en carretera en Savannah GA existe Cioffi Express Services.{" "}
                    <b>
                      ¡Estamos disponibles 24/7, no dudes en{" "}
                      <a target="_blank" href="tel:14076745769">
                        llamarnos
                      </a>
                      !
                    </b>
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <ArticleFooter />
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Savannah;
